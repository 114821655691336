/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql } from "gatsby"
import PageContainer from "./sections/PageContainer"
const PageDefinition = ({ data }) => {
  return <PageContainer node={data.page} />
}
export default PageDefinition

export const pageDefinitionQuery = graphql`
  query PageDefinitionBySlug($slug: String!) {
    page: sanityPageDefinition(slug: { current: { eq: $slug } }) {
      slug {
        current
      }
      metaTitle
      metaDescription
      title
      _rawHero(resolveReferences: { maxDepth: 10 })
      _rawEntities(resolveReferences: { maxDepth: 40 })
    }
  }
`
